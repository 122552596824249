import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { mix } from "polished";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Container } from "@ryerson/frontend.layout";
import HeroAlert from "@components/HomePage/Alerts/HeroAlert";
import { useApplication } from "@ryerson/frontend.application";
import MyAccountDashboardMenu from "@components/Shared/MyAccountDashboardMenu";

export interface HeroImageProps {
	className?: string;
	imageUrl: string;
	titleLine1?: string;
	titleLine2?: string;
	subtitle?: string;
	actionLink?: string;
	actionLabel?: string | string[];
	welcome?: string;
	heroSubTitle1?: string;
	orders?: string;
	and?: string;
	products?: string;
}

interface RenderPersonalizedHomePageProps {
	showImage?: boolean;
}

const HeroImage: React.FC<HeroImageProps> = ({ ...props }) => {
	const { theme } = useTheme();
	const {
		user: { isLoggedIn },
		profile,
	} = useApplication();
	const Image = styled.img`
		position: absolute;
		top: 0px;
		height: auto;
		width: auto;

		max-width: 85%;
		right: -87px;
		transform: rotate(-8deg);

		@media only screen and (min-width: ${theme.breakpoints.lg}) {
			max-width: 43%;
			right: 0px;
			transform: rotate(0deg);
		}
		@media only screen and (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
				.breakpoints.md}) {
			max-width: 53%;
			right: 0px;
			transform: rotate(0deg);
		}
		@media only screen and (min-width: ${theme.breakpoints.md}) and (max-width: ${theme
				.breakpoints.lg}) {
			max-width: 45%;
			right: 0px;
			transform: rotate(0deg);
		}
		@media only screen and (min-width: 400px) and (max-width: ${theme.breakpoints.sm}) {
			max-width: 63%;
			right: 0px;
			transform: rotate(0deg);
		}
	`;

	const STSImage = styled.div`
		display: block;
		top: 0px;
		height: 364px;
		position: absolute;
		left: 0;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	`;

	const ButtonLinkContainer = styled.div`
		display: block;
		position: absolute;
		bottom: 10px;
		text-align: right;
		right: 20px;
		width: 200px;
		height: 40px;
	`;

	const HeroWrapper = styled.div`
		padding-top: 50px;
		background-color: ${theme.colors.primary.secondaryBrand};
		position: relative;
		@media only screen and (max-width: ${theme.breakpoints.sm}) {
			overflow: hidden;
		}
		@media only screen and (width >= ${theme.breakpoints.md}) {
			padding-top: 0px;
		}
	`;

	const HeroTitleText = styled.div`
		@media only screen and (min-width: ${theme.breakpoints.lg}) {
			padding: 10px 0px 10px 20px;
			h1 {
				margin: 10px;
				position: relative;
				z-index: 1;
			}
		}
		@media only screen and (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
				.breakpoints.lg}) {
			padding: 0px;
		}
	`;

	const HeroSubTitleText = styled.div`
		padding: 69px 0px 10px 0px;

		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			position: relative;
			padding: 10px 0px;
			z-index: 1;
			white-space: pre-line;
		}
	`;

	const HeroTextDiv = styled.div`
		display: block;
		margin: 0 auto;
	`;

	const hoverColor = mix(0.65, theme.colors.primary.link, theme.colors.primary.background);

	const ProductLink = styled.span`
		color: ${theme.colors.primary.link};
		&:hover {
			cursor: pointer;
			color: ${hoverColor};
		}
		@media only screen and (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
				.breakpoints.lg}) {
			margin: 0 5px;
		}
	`;

	const LinkTypography = styled(Typography)`
		color: ${theme.colors.primary.link};
		font-family: ${theme.typography.fontFamilies.headings};
		&:hover {
			color: ${hoverColor};
		}
		@media only screen and (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme
				.breakpoints.lg}) {
			margin: 0 5px;
		}
	`;

	const StyledTypography = styled(Typography)`
		margin-left: -30px !important;
		margin: 10px;
		position: relative;
		z-index: 1;
		overflow-wrap: break-word;
		@media only screen and (${theme.breakpoints.lg}>width<${theme.breakpoints.xl}) {
			font-size: 56px;
			line-height: 70px;
		}
		@media only screen and (width>${theme.breakpoints.xl}) {
			font-size: 70px;
			line-height: 70px;
		}
		@media only screen and (width>1600px) {
			font-size: 75px;
		}
	`;

	const scrollToExpressSearch = () => {
		const viewportTop = window.scrollY;
		const stickyHeader = document.getElementsByClassName("sticky")[0];
		const elementPosition =
			(document as any)?.getElementById("expressSearchSection")?.getBoundingClientRect().top +
			viewportTop;
		const headerHeight = stickyHeader !== undefined ? 120 : 155;

		const scrollToPosition = elementPosition - headerHeight;
		window?.scrollTo({ behavior: "smooth", left: 0, top: scrollToPosition });
	};

	const RenderPersonalizedHomePage: React.FC<RenderPersonalizedHomePageProps> = ({
		showImage = true,
	}) => (
		<HeroWrapper className={props?.className}>
			<Container>
				<Flex direction="row">
					<Media greaterThanOrEqual="lg">
						<FlexItem>
							<MyAccountDashboardMenu isMobile={false} isHeroPage={true} />
						</FlexItem>
					</Media>
					<FlexItem grow={1}>
						<HeroTitleText>
							<Media between={["xs", "sm"]}>
								<Typography
									variant="h2"
									type="tertiary"
									css={{
										whiteSpace: "pre-line",
										marginTop: "-40px",
										overflowWrap: "break-word",
										width: "42vw",
									}}
								>
									{props?.welcome}
								</Typography>
								<Typography
									variant="h2"
									color={theme.colors.primary.white}
									css={{
										marginTop: "10px !important",
										overflowWrap: "break-word",
										width: "42vw",
									}}
								>
									{profile?.firstName}!
								</Typography>
								<span css={{ marginTop: "15px" }}>
									<HeroAlert />
								</span>
								<Typography
									type="tertiary"
									variant="h5"
									color={theme.colors.primary.lightGray}
									css={{ whiteSpace: "pre-line", marginTop: "15px" }}
								>
									{props?.heroSubTitle1} <br />
									<a
										css={{ textDecoration: "none" }}
										href={`/store/dashboard/orders`}
									>
										<LinkTypography>{props?.orders}</LinkTypography>
									</a>{" "}
									{props?.and}{" "}
									<ProductLink onClick={() => scrollToExpressSearch()}>
										{props?.products}
									</ProductLink>
								</Typography>
							</Media>
							<Media at="sm">
								<Typography
									variant="h2"
									type="tertiary"
									css={{
										whiteSpace: "pre-line",
										marginTop: "-40px",
										overflowWrap: "break-word",
										width: "50vw",
									}}
								>
									{props?.welcome}
								</Typography>
								<Typography
									variant="h2"
									color={theme.colors.primary.white}
									css={{
										overflowWrap: "break-word",
										width: "50vw",
										marginTop: "10px !important",
									}}
								>
									{profile?.firstName}!
								</Typography>
								<span css={{ marginTop: "5px" }}>
									<HeroAlert />
								</span>
								<Typography
									type="tertiary"
									variant="h5"
									color={theme.colors.primary.lightGray}
									css={{
										whiteSpace: "pre-line",
										marginTop: "15px",
										display: "inline-flex",
									}}
								>
									{props?.heroSubTitle1} <br />
									<a
										css={{ textDecoration: "none" }}
										href={`/store/dashboard/orders`}
									>
										<LinkTypography>{props?.orders}</LinkTypography>
									</a>{" "}
									{props?.and}{" "}
									<ProductLink onClick={() => scrollToExpressSearch()}>
										{props?.products}
									</ProductLink>
								</Typography>
							</Media>
							<Media at="md" css={{ overflowWrap: "break-word" }}>
								<Typography
									variant="h1"
									type="tertiary"
									color={theme.colors.primary.white}
									css={{
										whiteSpace: "pre-line",
										width: "50vw",
									}}
								>
									{props?.welcome}
								</Typography>
								<Typography
									variant="h1"
									color={theme.colors.primary.white}
									css={{ lineHeight: "55px" }}
								>
									{profile?.firstName}!
								</Typography>
								<span css={{ marginTop: "15px" }}>
									<HeroAlert />
								</span>
								<Typography
									type="tertiary"
									variant="h5"
									color={theme.colors.primary.lightGray}
									css={{
										whiteSpace: "pre-line",
										marginTop: "15px",
										display: "inline-flex",
									}}
								>
									{props?.heroSubTitle1} <br />
									<a
										css={{ textDecoration: "none" }}
										href={`/store/dashboard/orders`}
									>
										<LinkTypography>{props?.orders}</LinkTypography>
									</a>{" "}
									{props?.and}{" "}
									<ProductLink onClick={() => scrollToExpressSearch()}>
										{props?.products}
									</ProductLink>
								</Typography>
							</Media>
							<Media greaterThanOrEqual="lg" css={{ width: "39vw" }}>
								<StyledTypography
									variant="h1"
									color={theme.colors.primary.white}
									css={css`
										white-space: pre-line;
									`}
								>
									{props?.welcome}
								</StyledTypography>
								<StyledTypography
									variant="h1"
									css={css`
										margin-top: 20px !important;
									`}
									color={theme.colors.primary.white}
								>
									{profile?.firstName}!
								</StyledTypography>
								<Typography
									type="tertiary"
									variant="h5"
									color={theme.colors.primary.lightGray}
									css={{
										whiteSpace: "pre-line",
										fontSize: "21px",
										marginTop: "45px !important",
										marginLeft: "-30px !important",
										width: "90%",
										lineHeight: "28px",
									}}
								>
									{props?.heroSubTitle1}{" "}
									<a
										css={{ textDecoration: "none" }}
										href={`/store/dashboard/orders`}
									>
										<LinkTypography>{props?.orders}</LinkTypography>
									</a>{" "}
									{props?.and}{" "}
									<ProductLink onClick={() => scrollToExpressSearch()}>
										{props?.products}
									</ProductLink>
								</Typography>
							</Media>
						</HeroTitleText>
						{showImage && <Image src={props?.imageUrl}></Image>}
						<Media greaterThanOrEqual="lg">
							<HeroAlert />
						</Media>
					</FlexItem>
				</Flex>
			</Container>
		</HeroWrapper>
	);

	if (process.env.GATSBY_COMPANY === "southernToolSteel") {
		return isLoggedIn ? (
			<RenderPersonalizedHomePage showImage={false} />
		) : (
			<HeroWrapper
				className={props?.className}
				css={css`
					display: block;
					width: 100%;
					height: 465px;
					@media (max-width: ${theme.breakpoints.md}) {
						height: 245px;
					}
				`}
			>
				<STSImage
					css={css`
						background-image: url(${props?.imageUrl});
						z-index: 0;
						@media (max-width: ${theme.breakpoints.md}) {
							height: 245px;
						}
					`}
				/>
				<HeroTextDiv
					css={css`
						width: 100%;
						max-width: ${theme.breakpoints.xl};
						position: relative;
						z-index: 1;
						padding-top: 80px;
						padding-left: 20px;
						padding-right: 20px;
						box-sizing: border-box;
						@media (max-width: ${theme.breakpoints.md}) {
							padding-top: 45px;
						}
					`}
				>
					<Typography
						variant="h1"
						color={theme.colors.primary.white}
						css={css`
							font-size: 131px;
							line-height: 120px;
							white-space: pre-line;
							@media (max-width: ${theme.breakpoints.xl}) {
								font-size: 90px;
								line-height: 80px;
							}
							@media (max-width: ${theme.breakpoints.md}) {
								font-size: 60px;
								line-height: 50px;
							}
						`}
					>
						{props?.titleLine1}
					</Typography>
					<Typography
						variant="h1"
						css={css`
							font-size: 131px;
							line-height: 120px;
							@media (max-width: ${theme.breakpoints.xl}) {
								font-size: 90px;
								line-height: 80px;
							}
							@media (max-width: ${theme.breakpoints.md}) {
								font-size: 60px;
								line-height: 50px;
							}
						`}
						color={theme.colors.primary.lightGray}
					>
						{props?.titleLine2}
					</Typography>

					<Media greaterThanOrEqual="lg">
						<ButtonLinkContainer>
							<ButtonLink
								orientation="right"
								linkType="internal"
								label={props?.actionLabel ?? ""}
								to={props?.actionLink ?? "#"}
								type="tertiary"
							/>
						</ButtonLinkContainer>
					</Media>
				</HeroTextDiv>
				<Media lessThan="lg">
					<ButtonLinkContainer
						css={css`
							@media (max-width: ${theme.breakpoints.md}) {
								bottom: 0px;
							}
						`}
					>
						<ButtonLink
							orientation="right"
							linkType="internal"
							size="sm"
							label="Learn More Here"
							to={props?.actionLink ?? "#"}
							type="tertiary"
						/>
					</ButtonLinkContainer>
				</Media>
			</HeroWrapper>
		);
	} else {
		return isLoggedIn ? (
			<RenderPersonalizedHomePage />
		) : (
			<HeroWrapper className={props?.className}>
				<Container>
					<HeroSubTitleText>
						<Typography size="lg" type="tertiary">
							{props?.subtitle}
						</Typography>
					</HeroSubTitleText>
					<HeroTitleText>
						<Media between={["xs", "sm"]}>
							<Typography
								variant="h1"
								type="tertiary"
								css={{ whiteSpace: "pre-line", fontSize: "2.5em" }}
							>
								{props?.titleLine1}
							</Typography>
							<Typography
								variant="h1"
								color={theme.colors.primary.gray}
								css={{ fontSize: "2.5em" }}
							>
								{props?.titleLine2}
							</Typography>
						</Media>
						<Media at="sm">
							<Typography
								variant="h1"
								type="tertiary"
								css={{ whiteSpace: "pre-line", fontSize: "3.5em" }}
							>
								{props?.titleLine1}
							</Typography>
							<Typography
								variant="h1"
								color={theme.colors.primary.gray}
								css={{ fontSize: "3.5em" }}
							>
								{props?.titleLine2}
							</Typography>
						</Media>
						<Media at="md">
							<Typography
								variant="h1"
								type="tertiary"
								css={{
									whiteSpace: "pre-line",
									fontSize: "4em",
									lineHeight: "55px",
								}}
							>
								{props?.titleLine1}
							</Typography>
							<Typography
								variant="h1"
								color={theme.colors.primary.gray}
								css={{ fontSize: "4em", lineHeight: "55px" }}
							>
								{props?.titleLine2}
							</Typography>
						</Media>

						<Media greaterThanOrEqual="lg">
							<Typography size="xl" variant="div" color={theme.colors.primary.white}>
								{props?.titleLine1}
							</Typography>
							<Typography size="xl" variant="div">
								{props?.titleLine2}
							</Typography>
						</Media>
					</HeroTitleText>
					<Image src={props?.imageUrl}></Image>
					<HeroAlert></HeroAlert>
				</Container>
			</HeroWrapper>
		);
	}
};

export default HeroImage;
